.navbar-container {
  background-color: #fdc000;
  display: flex;
  position: relative;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.hazard-logo {
  position: absolute;
  left: 6%;
  width: 145px;
  top: 32%;
}

.navbar-menu {
  position: absolute;
  right: 2%;
}

.user-info-container {
  background-color: black;
  color: white;
  display: flex;
  position: relative;
  height: 25px;
  align-items: center;
  justify-content: left;
  svg {
    margin-left: 10px;
    font-size: 20px;
    color: #fdc000;
  }
  p {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    font-size: 12px;
    line-height: 12px;
  }
}

.toggle-button-container {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -1px rgba(0, 0, 0, 0.12);
  height: 55px;
  display: flex;
}

.photo-nav-btn,
.video-nav-btn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  h4 {
    font-size: 15px;
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    color: white;
    background-color: #0E6AAD;
    h4 {
      color: #fff;
    }
  }
}

.blue-triangle-1 {
  position: relative;
  left: 22%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 12.5px 0 12.5px;
  border-color: #0e6bad transparent transparent transparent;
}

.blue-triangle-2 {
  position: relative;
  left: 71%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 12.5px 0 12.5px;
  border-color: #0e6bad transparent transparent transparent;
}

li.filter-checkbox-list-item {
  padding: 0;
  height: 40px;
  .filter-checkbox-text {
    padding-left: 0;
    cursor: pointer;
    span {
      font-size: 13px;
    }
  }
}

// .ripple {
//   background-position: center;
//   transition: background 0.8s;
// }
// .ripple:hover {
//   background: #EBEBEB radial-gradient(circle, transparent 1%, #EBEBEB 1%) center/15000%;
// }
// .ripple:active {
//   background-color: #cecece;
//   background-size: 100%;
//   transition: background 0s;
// }

li.filter-user-list-item {
  padding: 0 25px;
  height: 27px;
  cursor: pointer;
  .filter-user-text {
    padding-left: 0;
    span {
      font-size: 13px;
    }
  }
}

.filters-main-container {
  position: fixed;
  width: 270px;
  bottom: 0;
  height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: visible;
  .scroll-hidden {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -15px;
    padding: 0px 3px 0px 15px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;
  }
}
.filter-area {
  margin: 10px;
  .expansion-panel-top {
    display: table;
    .filter-title {
      display: table-cell;
    }
    .top-level-tag {
      display: table-cell;
      padding-left: 10px;
      color: #0E6AAD;
      font-size: 12px;
      white-space: nowrap;
    }
  }
  .top-level-hazard-tags-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .top-level-hazard-tags {
    height: 16px;
    display: table;
    -webkit-border-horizontal-spacing: 5px;
    .level-1 {
      width: 16px;
      background-color: #F33;
      border: 1px solid #000;
      border-radius: 5px;
      height: 100%;
      display: table-cell;
    }
    .level-2 {
      width: 16px;
      background-color: #FC3;
      border: 1px solid #000;
      border-radius: 5px;
      height: 100%;
      display: table-cell;
    }
    .level-3 {
      width: 16px;
      background-color: #CF3;
      border: 1px solid #000;
      border-radius: 5px;
      height: 100%;
      display: table-cell;
    }
    .level-4 {
      width: 16px;
      background-color: #3CF;
      border: 1px solid #000;
      border-radius: 5px;
      height: 100%;
      display: table-cell;
    }
  }
}
.place-filter-section {
  .place-filter-divider {
    border: .5px solid #e8e8e8;
    margin: 16px 0px 15px 0px;
  }
  .place-filter-section-title {
    margin-left: 5px;
    font-weight: bold;
    color: rgb(14, 106, 173);;
    &.live-title {
      margin-left: 15px;
    }
  }
}
.picker-start {
  float: left;
  width: 100%;
  padding: 0 23px;
  margin-bottom: 15px;
  input {
    width: 100%;
    min-width: 225px;
    font-size: 12px;
  }
  > div:first-child {
    float: left;
    width: 100%;
  }
}
.picker-end {
  float: left;
  width: 100%;
  padding: 0 23px;
  margin-bottom: 15px;
  input {
    width: 100%;
    min-width: 225px;
    font-size: 12px;
  }
  > div:first-child {
    float: left;
    width: 100%;
  }
}
.date-filter-buttons {
  float: left;
  padding: 0 23px;
  margin-bottom: 10px;
  .filter-button {
    background-color: #fc3;
    color: #000;
    &.today {
      min-width: 42px;
      margin-right: 5px;
    }
    &.week {
      min-width: 50px;
      margin-right: 5px;
    }
    &.active-range {
      background-color: #CCA328;
      &:hover {
        background-color: #CCA328;
      }
    }
    &:hover {
      background-color: #E5B72D;
    }
  }
}
[class*='MuiPickersCalendarHeader-dayLabel']:last-of-type {
  color: "pink" !important
}
.level-1 {
  height: 15px;
  width: 5px;
  background-color: #F33;
  margin-right: 10px;
}
.level-2 {
  height: 15px;
  width: 5px;
  background-color: #FC3;
  margin-right: 10px;
}
.level-3 {
  height: 15px;
  width: 5px;
  background-color: #CF3;
  margin-right: 10px;
}
.level-4 {
  height: 15px;
  width: 5px;
  background-color: #3CF;
  margin-right: 10px;
}
.color-category-label {
  height: 15px;
  width: 5px;
  margin-right: 10px;
  background-color: #ddd;
  &.blue {
    background: #5781FC;
  }
  &.yellow {
    background: #FCF357;
  }
  &.purple {
    background: #7E55FC;
  }
  &.pink {
    background: #E14F9E;
  }
  &.orange {
    background: #FF9900;
  }
  &.lightblue {
    background: #55D7D7;
  }
  &.green {
    background: #00E13C;
  }
  &.brightred {
    background: #FF3333;
  }
  &.brightorange {
    background: #FFCC33;
  }
  &.brightgreen {
    background: #CCFF33;
  }
  &.brightblue {
    background: #33CCFF;
  }
}



