.edit-place-dialog-container {
  width: 100%;
  position: absolute;
  float: left;
}

.filter-edit-dialog-container {
  margin: 10px 35px;
}

.cat-drill-down-list-container {
  margin: 10px 35px;
}

.dialog-nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  border-color: #e7e7e7;
  background-color: #EBEBEB;
  &.separate-nav {
    position: absolute;
    top: 0;
    float: left;
  }
}

.edit-place-nav-container {
  width: 100%;
  padding: 10px 0;
  border: 1px solid transparent;
  border-radius: 5px;
  border-color: #e7e7e7;
  background-color: #EBEBEB;
  position: relative;
  float: left;
  button {
    margin-left: 10px;
  }
  &.separate-nav {
    position: absolute;
    top: 0;
    float: left;
  }
}

.separate-nav-wrap {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: block;
  .back-btn {
    color: white;
    background-color: rgb(13, 106, 173);
    float: left;
    &:hover {
      background-color: #0A5387;
    }
  }
  .save-btn {
    color: white;
    background-color: green;
    float: right;
    &:hover {
      background-color: mediumseagreen;
    }
  }
  .delete-btn {
    float: right;
    margin-right: 10px;
  }
}

.add-group-form-container {
  border: 1px solid #e7e7e7;
  padding: 10px;
  border-radius: 5px;
  &.dark-mode {
    textarea {
      color: #fff;
    }
  }
}
.edit-place-form-container {
  width: 100%;
  display: block;
  margin: 100px auto;
  max-width: 1200px;
}

.edit-place-info-container {
  top: -10px;
  left: 0;
  position: absolute;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 600px;
  @media(max-width: 991px) {
    position: initial;
    max-width: 100%;
    clear: both;
    padding: 5px;
  }
  .edit-place-info {
    overflow: hidden;
    word-wrap: break-word;
    float: left;
    background-color: #FFF;
    width: 33.333333%;
    height: 75px;
    padding-top: 10px;
    box-shadow: 0px 10px 25px 0px #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    @media(max-width: 600px) {
      width: 100%;
      text-align: center;
      height: auto;
      padding: 10px;
    }
    &:first-child {
      padding-left: 20px;
      border-bottom-left-radius: 10px;
      border-right: 1px solid #EBEBEB;
      @media(max-width: 991px) {
        border-top-left-radius: 10px;
        padding-left: 10px;
      }
      @media(max-width: 600px) {
        border-radius: 0;
      }
      .info-text-wrap {
        p:nth-child(2) {
          font-size: 18px;
          font-weight: bolder;
          @media(max-width: 1380px) {
            font-size: 16px;
          }
          @media(max-width: 600px) {
            display: block;
            text-align: center;
          }
        }
      }
    }
    &:nth-child(2) {
      border-right: 1px solid #EBEBEB;
    }
    &:nth-child(3) {
      border-bottom-right-radius: 10px;
      @media(max-width: 991px) {
        border-top-right-radius: 10px;
      }
      @media(max-width: 600px) {
        border-radius: 0;
      }
    }
    .info-text-wrap {
      &.date {
        left: 30px;
        position: relative;
        @media(max-width: 600px) {
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
        }
      }
      p:first-child {
        color: #0d6aad;
        font-size: 14px;
        @media(max-height: 666px) {
          font-size: 12px;
        }
      }
      p:nth-child(2) {
        @media(max-height: 666px) {
          font-size: 14px;
        }
      }
    }
  }
}

.pin-color-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
}

.edit-place-color-picker-row {
  width: 100%;
  float: left;
}

.edit-place-color-picker {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  padding: 14px;
  float: left;
  width: calc(100% - 649px);
  margin-right: 10px;
  @media(max-width: 1130px) {
    width: calc(100% - 569px);
  }
  @media(max-width: 767px) {
    width: 50%;
  }
  .color-picker-text {
    margin-bottom: 10px;
    text-align: center;
  }
  .color-picker-radio {
    margin-bottom: 4px;
    margin-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    width: 100%;
  }
}

.folder-checkbox {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.checkbox-pos {
  margin: 15px auto;
  float: left;
  width: 100%;
  &.center-check-true {
    &.is-taisaku {
      margin-top: 35px;
    }
  }
  &.center-check-false {
    &.no-taisaku {
      margin-top: 35px;
    }
  }
}
.edit-taisaku-checkbox {
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  padding: 14px;
  float: left;
  width: 145px;
  height: 172px;
  text-align: center;
  p {
    float: left;
    width: 100%;
  }
  @media(max-width: 767px) {
    width: calc(50% - 20px);
  }
  .taisaku-label {
    margin: 0 auto;
    text-align: center;
    margin-left: 4px;
  }
}
.incident-edit-list-item-text span{
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.incident-checkbox-container {
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  padding: 14px;
  float: left;
  text-align: left;
  height: 172px;
  width: 319px;
  @media(max-width: 1130px) {
    width: 250px;
  }
  @media(max-width: 767px) {
    width: 50%;
  }
  &.view-more-incidents {
    height: 100%;
  }
  > li {
    padding: 2px 0px;
  }
  > li > span {
    padding: 0px;
  }
  > li > div {
    padding: 0px;
  }
  .view-more-incidents-btn {
    cursor: pointer;
    text-decoration: underline;
    color: #0d6aad;
    font-size: 16px;
    text-align: left;
  }
  @media(max-height: 666px) {
    height: 75px;
    > p {
      font-size: 10px;
      font-weight: bolder;
    }
    > div > p {
      font-size: 12px;
    }
  }
  .incident-checkbox {
    span {
      display: inline-block;
    }
    p {
      display: inline-block;
    }
  }
}

.folder-checkbox-filter {
  display: flex;
  align-items: center;
  padding: 0;
  p {
    cursor: pointer;
  }
}

.incident-settings-btn {
  &.hazard-live {
    background-color: #000 !important;
    border: 2px solid #fc3;
    &:hover {
      background-color: rgb(32, 32, 32) !important;
    }
  }
  .settings-wrench {
    color: #000;
    margin-right: 5px;
    &.hazard-live {
      color: #FFF;
      fill: #fc3
    }
  }
}

.check-all-filter {
  display: flex;
  align-items: center;
  padding: 0;
  &.groups {
    padding: 0;
  }
}

.make-public-button-container {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  padding: 13px;
  float: right;
  text-align: center;
  height: 172px;
  @media(max-width: 767px) {
    width: calc(50% - 20px);
  }
}
.public-row {
  align-items: center;
  @media(max-height: 666px) {
    > button {
      font-size: 12px;
      height: 30px;
    }
  }
}
.public-btn-side-text {
  margin-bottom: 10px;
  p {
    white-space: break-spaces;
    max-width: 114px;
    margin: 20px auto 45px auto;
  }
  button {
    font-size: 13px;
  }
  @media(max-height: 666px) {
    margin-bottom: 4px;
    > p {
      font-size: 10px;
    }
  }
}
.comment-map-container {
  height: 300px;
  margin: 10px 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}
.edit-place-map-container {
  height: 250px;
  margin: 10px 0;
  margin-bottom: 0;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  @media(max-height: 666px) {
    height: 150px;
  }
}

.edit-username-form-container {
  padding: 20px;
  text-align: center;
}

.remove-user-modal > div > div:first-child {
  max-width: 500px;
  width: 100%;
  display: flex;
  height: 160px;
  padding: 20px;
  .header {
    margin-bottom: 5px;
  }
  .body {
    margin: 10px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.description-photo-row {
  width: 100%;
  .filter-dialog-description {
    width: 100%;
    float: left;
    &.with-photo {
      width: 48%;
    }
  }
  .filter-dialog-photo-container {
    width: 48%;
    float: right;
    > img {
      margin-top: 16px;
    }
  }
}