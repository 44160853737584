.ui-video-seek-slider {
  width: calc(100% - 65px);
  float: left;
  position: relative;
  top: 5px;
  left: 12px;
}
.ui-video-seek-slider:focus {
  outline: none;
}
.ui-video-seek-slider .track {
  padding: 10px 0;
  cursor: pointer;
  outline: none;
}
.ui-video-seek-slider .track:focus {
  border: 0;
  outline: none;
}
.ui-video-seek-slider .track .main {
  width: 100%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  position: absolute;
  left: 0;
  top: 8.5px;
  overflow: hidden;
  transition: transform 0.4s;
  outline: none;
}
.ui-video-seek-slider .track .main:focus {
  border: 0;
  outline: none;
}
.ui-video-seek-slider .track .main .buffered {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  transform: scaleX(0.8);
  z-index: 2;
  transform-origin: 0 0;
  transition: 0.5s transform;
}
.ui-video-seek-slider .track .main .seek-hover {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: scaleX(0.6);
  transform-origin: 0 0;
  opacity: 0;
  transition: opacity 0.4s;
}
.ui-video-seek-slider .track .main .connect {
  position: absolute;
  background-color: #f00;
  width: 100%;
  height: 100%;
  z-index: 3;
  left: 0;
  transform: scaleX(0.13);
  transform-origin: 0 0;
}
.ui-video-seek-slider .track.active .main {
  transform: scaleY(2);
}
.ui-video-seek-slider .thumb {
  pointer-events: none;
  position: absolute;
  width: 12px;
  height: 12px;
  left: -6px;
  top: 4px;
  z-index: 4;
  transform: translateX(100px);
}
.ui-video-seek-slider .thumb .handler {
  transition: transform 0.2s;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: #f00;
  opacity: 0;
  transform: scale(0.4);
}
.ui-video-seek-slider .thumb.active .handler {
  opacity: 1;
  transform: scale(1);
}
.ui-video-seek-slider .hover-time {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 18px;
  font-size: 16px;
  color: #ddd;
  top: -25px;
  left: 0;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateX(150px);
  pointer-events: none;
}
.ui-video-seek-slider .hover-time.active {
  opacity: 1;
}
.ui-video-seek-slider:hover .track .main .seek-hover {
  opacity: 1;
}
