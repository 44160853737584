.success-snackbar-alert-wrap > div:first-child {
  background-color: #9bd466;
  margin-top: 10px;
}

.error-snackbar-alert-wrap > div:first-child{
  background-color: #fd4455;
  margin-top: 10px;
}

.connection-error-snackbar-alert-wrap > div:first-child{
  background-color: #f9edbe;
  margin-top: 10px;
}

.warning-snackbar-alert-wrap > div:first-child{
  background-color: #fdc000;
  margin-top: 10px;
}

.error-icon-snackbar {
  float: left;
  position: absolute;
  left: 11px;
}

.connection-error-icon-snackbar {
  float: left;
  position: absolute;
  left: 11px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: black;
}

.success-icon-snackbar {
  float: left;
  position: absolute;
  left: 11px;
}

.warning-icon-snackbar {
  float: left;
  position: absolute;
  left: 11px;
  color: black;
}

.error-alert-message {
  padding-left: 15px;
}

.connection-error-alert-message {
  padding-left: 15px;
  color: black;
}

.warning-alert-message {
  padding-left: 15px;
  color: black;
}

.success-alert-message {
  padding-left: 15px;
}

.form-has-error {
  label[class*="error"] {
    color: #f86d8f;
  }
  div[class*="error"] {
    &:after {
      border-bottom-color: #f86d8f;
    }
  }
}
#alert-message-content {
  float: left;
}
.error-boundary-alignment {
  display: flex;
  justify-content: center;
  text-align: center;
}

div.snackbar-main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 500px;
  transform: translate(0);
  > div:first-child {
    padding: 10px 0px 10px 25px;
  }
  .icon-snackbar {
    top: 0;
    left: 10px;
    bottom: 0;
    margin: auto;
  }
}
.close-new-places-alert {
  position: absolute;
  top: 0;
  left: 0;
}
@keyframes flash {
  0% { background-color: #ffe0e0; }
  50% { background-color: #feb6b6; }
  100% { background-color: #ffe0e0; }
}

@keyframes scale-pop {
  0% { transform: scale(0.9); }
  50% { transform: scale(1.08); }
  100% { transform: scale(1); }
}
.flash-wobble {
  animation: flash 1s infinite, scale-pop 0.5s ease;
}
.new-places-found {
  float: left;
  width: calc(100% - 10px);
  background-color: #ffe0e0;
  padding: 10px;
  position: relative;
  margin: 5px;
  border-radius: 7px;
  transition: all 0.3s ease-in-out;
  display: none;
  &.has-new {
    display: block;
  }
  p {
    float: left;
    color: #f77579;
    user-select: none;
    padding: 9px 0 0 20px;
  }
  .load-new-places {
    a {
      float: right;
      text-decoration: none;
      &focus,
      &:hover {
        text-decoration: none;
      }
    }
    button {
      background-color: #ff6166;
      color: #fff;
      margin-left: 10px;
      &:hover {
        background-color: #fbc8ca;
        color: #ff8787;
      }
    }
  }
  .close-new-places-alert {
    button {
      float: left;
      background-color: #ff6166;
      color: #fff;
      font-size: 12px;
      border-radius: 10px;
      border: 1px solid #ff6166;
      border-top-right-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0;
      &:focus {
        outline: 0;
      }
      &:hover {
        cursor: pointer;
        outline: 0;
        background-color: #fbc8ca;
        color: #ff8787;
      }
    }
  }
}
.public-list-empty {
  float: left;
  width: calc(100% - 10px);
  background-color: #f4f4f4;
  padding: 10px;
  margin: 5px;
  border-radius: 7px;
  transition: all 0.3s ease-in-out;
  display: none;
  &.is-empty {
    display: block;
  }
  p {
    float: left;
    color: #797979;
    user-select: none;
  }
}
.stream-active-alert {
  position: absolute;
  top: 0;
  background-color: #ff0047;
  padding: 0px 8px 1px 7px;
  -webkit-transform: skew(-9deg, 0deg);
  transform: skew(-9deg, 0deg);
  right: -1px;
  bottom: 0;
  margin: auto;
  height: 16px;
  p {
    color: #fff;
    font-size: 11px;
  }
}
.history-record {
  float: left;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
  z-index: 9;
  background-color: #fff;
  span {
    font-size: 12px;
    font-weight: normal;
    float: left;
    width: 100%;
  }
  .msg-title {
    float: left;
    width: 100%;
    font-weight: bolder;
    font-size: 16px;
    padding: 10px 0 0 0;
  }
  .msg-content {
    border-bottom: 1px solid #ddd;
    float: left;
    width: 100%;
    padding: 10px 0px;
    margin-bottom: 10px;
    word-break: break-word;
  }
  .sent-to-devices {
    float: left;
    width: 100%;
    padding: 15px 0;
  }
  .notification-type {
    position: absolute;
    top: 10px;
    right: 45px;
    background-color: #3e55ff;
    color: #fff;
    border-radius: 25px;
    &.update_location {
      background-color: #8bc34a;
    }
    p {
      color: #fff;
      padding: 5px 10px;
    }
  }
  .history-delete-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: inset 0px 0px 0px 0px #f5978e;
    background:#F33;
    background-color: #F33;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    text-indent: 0;
    border: 1px solid #d02718;
    display: inline-block;
    color: #ffffff;
    font-family: arial;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-decoration: none;
    text-align: center;
    text-shadow: 0px 1px 0px #810e05;
    cursor: pointer;
  }
  .history-delete-btn:hover {
    background: #c62d1f;
    background-color: #c62d1f;
  }
  .history-delete-btn:active {
    position: absolute;
    top: 11px;
  }
  .sent-time {
    float: right;
    width: 100%;
    text-align: right;
    font-size: 13px;
    color: #848484;
    span {
      width: initial;
      text-align: right;
      float: none;
      margin-right: 5px;
    }
  }
}
