.login-page-container {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
}
.login-signup-form {
  padding: 20px;
  width: 100%;
  max-width: 350px;
  position: absolute;
  width: 400px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 305px;
  padding-top: 75px;
}

.login-btn-wrap {
  width: 100%;
  margin-top: 20px;
}
.login-form-logo-container {
  background-color: #fdc000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  svg {
    max-width: 200px;
    margin: auto;
    display: block;
  }
}