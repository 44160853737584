.realtime-map-builder {
    position: fixed;
    top: calc(-100% + 40px);
    left: 0;
    width: calc(100% - 280px);
    z-index: 991;
    margin: auto;
    height: calc(100% - 40px);
    background-color: #000;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0;
    transition: all 0.5s ease-in-out;
    &.opened-builder {
      top: 0;
       &.expand {
        width: 100%;
      }
    }
    button.map-dropdown-btn {
      width: 165px;
      color: #fff;
      background-color: #120e0e;
      border-radius: 0;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 8px 10px 8px 10px;
      position: absolute;
      right: 175px;
      margin: auto;
      bottom: -40px;
      z-index: 3;
      box-shadow: 0px -6px 21px 0px rgba(0, 0, 0, 0.23);
      transition: all 0.5s ease-in-out;
      &.opened {
        right: 50px;
        border-bottom-right-radius: 0;
      }
      &.hidden {
        display: none;
      }
      &:hover {
        background-color: #000;
      }
      svg {
        width: 17px;
        margin-right: 6px;
        margin-bottom: 1px;
      }
    }
  }
  .map-mode-navbar {
    float: left;
    width: 100%;
    height: 50px;
    .logo svg {
      float: left;
      width: 189px;
      margin: 20px 0 0 27px;    
      .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
      .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#F21439;}
      .st2{fill:#F21439;}
    }
  }
  button.close-map {
    position: absolute;
    right: 23px;
    top: 11px;
    bottom: 0;
    color: #ddd;
    height: 35px;
    width: 35px;
    background-color: #2b2b2b;
    border-radius: 50%;
    min-width: initial;
    min-height: initial;
    &:hover {
      background-color: #464344;
    }
    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .map-grid-container {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    height: calc(100% - 50px);
    width: 100%;
    padding: 0 5px 5px 5px;
    z-index: 9;
    background-color: #000;
    h5 {
      color: #fff;
      font-size: 15px;
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 20px;
    }
  }
  .livebar {
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 100%;
    height: 66px;
    background-color: #000;
    background-image: url('../images/hazardlive-banner.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    opacity: 0;
    transition: all 1s ease-in-out;
    display: none;
    h5 {
      color: #fff;
      position: absolute;
      bottom: -30px;
      font-size: 13px;
      left: 11px;
    }
    &.opened {
      display: block;
      opacity: 1;
      z-index: 1;
    }
    .livebar-hook {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 26px;
      width: 100%;
      background-color: #1b1b1b;
      border-bottom-left-radius: 30px;
    }
  }
  .live-screen-side-bar-actions {
    background-color: #333;
    position: absolute;
    right: 0;
    bottom: 0;
    button {
      float: right;
      background-color: #FFC107;
      text-transform: none;
      width: 51px;
      height: 40px;
      min-width: initial;
      border-radius: 0;
      &:hover {
        background-color: #ff7c07;
      }
      svg {
        transform: rotate(0deg);
      }
      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .realtime-map-container {
      width: 100%;
      height: 100%;
      .realtime-map {
        width: 100%;
        height: 100%;
        width: 100%;
        height: calc(100% - 50px);
        z-index: 2;
        border-bottom-left-radius: 20px;
      }
  }