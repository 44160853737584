h4,
h5,
h6 {
  margin: 0;
}
.show-photo-list {
  display: block;
}
.show-video-list {
  display: block;
  float: left;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.hide-video-list,
.hide-photo-list {
  display: none;
}
.show-video-list.dark-mode {
  float: left;
  width: 100%;
  min-height: 100%;
  background-color: #1b1b1b;
  overflow-y: hidden;
  .live-places-wrapper {
    top: 0px;
    height: 100%;
  }
  .amount-per-page-action-container {
    display: none;
  }
  .is-live-tag-list {
    color: #fff;
  }
  .amount-per-page-action-container {
    background-color: #242527;
    input, select, textarea { 
      color: #fff;
    }
    svg {
      color: #fff;
    }
    form > div:first-child > div:first-child {
      &:before {
        border-color: #828282;
      }
      &:after {
        border-color: #e2a708;
      }
      > div:first-child {
        color: #fff;
      }
    }
  }
  .span.amount-per-page-label {
    color: #d4d4d4;
  }
  li.list-item-container {
    background-color: #191919;
    border: 1px solid #252525;
    max-width: 270px;
    clear: both;
  }
  h4.place-list-item-group-name {
    color: #0094ff;
  }
  .place-list-item-date h3{
    color: #fff;
  }
  .place-list-item-username-container {
    h5 {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
}
.places-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding-right: 15px;
  margin-left: 10px;
  width: 270px;
  transition: all 0.3s ease-in-out;
  &.has-new {
    top: 230px;
    height: calc(100% - 230px);
  }
  &.no-new {
    top: 155px;
    height: calc(100% - 155px);
  }
  &.public-tab-hidden {
    &.has-new {
      top: 180px;
      height: calc(100% - 180px);
    }
    top: 105px;
    height: calc(100% - 105px);
  }
}
.live-places-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding-right: 15px;
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
  top: 50px;
  height: calc(100% - 50px);
}
ul.place-list-container {
  width: 100%;
  &.has-public-tabs {
    padding: 0;
  }
}
li.list-item-container {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 12px 5px 18px rgba(0, 0, 0, 0.05), 9px 8px 10px rgba(0, 0, 0, 0.12);
    transform: translate(2px, -2px);
  }
  &.live-stream-list-container {
    .place-thumb-image-container {
      &.isLiveStream {
        background-image: url('../images/incoming-stream.jpg');
      }
      &.hasStorage {
        background-image: url('../images/livestream-ended-large.jpg');
      }
    }
    .place-list-item-group-name {
      margin-bottom: 4px;
    }
  }
}
div.live-stream-thumb {
  width: 68px;
  height: calc(100% - 10px);
  position: absolute;
  left: 32px;
  top: 5px;
  bottom: 0;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  &.processing-thumb {
    background-image: url('../images/livestream-ended-large.jpg');
  }
}
.place-thumb-image-container {
  width: 68px;
  height: calc(100% - 10px);
  position: absolute;
  left: 32px;
  top: 5px;
  bottom: 0;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  &.sos-place-image {
    left: 30px;
  }
}
.place-list-item-content {
  float: left;
  margin-left: 90px;
  margin-top: 14px;
  width: calc(100% - 90px);
  &.sos-place-card {
    margin-top: 10px;
  }
}
.place-list-item-username-container {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  svg {
    float: left;
    width: 15px;
    height: 17px;
  }
  h5 {
    float: left;
    font-size: 13px;
  }
  .large-username {
    font-size: 14px;
  }
}
h4.place-list-item-live-title {
  margin-top: 5px;
  font-size: 16px;
  float: left;
  width: 100%;
}
h4.place-list-item-group-name {
  color: #0e6aad;
  margin-top: 5px;
  font-size: 13px;
  float: left;
  width: 100%;
  &.large-name {
    font-size: 18px;
  }
}

.place-list-item-date {
  float: left;
  width: 100%;
  h3 {
    font-size: 10px;
    float: left;
    width: 100%;
    margin: 3px 0px 0 0;
    color: #797979;
    &.timestamp-hours {
      position: absolute;
      bottom: 10px;
      right: 10px;
      left: auto;
      left: initial;
      width: 70px;
      text-align: right;
      margin: 0;
      color: #9c9c9c;
    }
    &.large-username {
      font-size: 13px;
    }
  }
}
h5.place-list-item-notes {
  font-size: 10px;
  color: #000;
  float: left;
  width: 100%;
  height: 26px;
  overflow: hidden;
  &.large-notes {
    font-size: 12px;
    width: 100%;
    overflow: initial;
    height: auto;
  }
}
.photo-amount {
  font-size: 12px;
  &.large {
    font-size: 16px;
  }
}
.place-list-incident-tags-container {
  float: left;
  width: 100%;
}
h5.place-list-incident-tag-name {
  font-size: 10px;
  color: #000;
  float: left;
  margin-right: 2px;
  &.hazard-live {
    color: #FFF
  }
}
.place-list-incident-tag-color {
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 2px;
  &.blue {
    background: #5781FC;
  }
  &.yellow {
    background: #FCF357;
  }
  &.purple {
    background: #7E55FC;
  }
  &.pink {
    background: #E14F9E;
  }
  &.orange {
    background: #FF9900;
  }
  &.lightblue {
    background: #55D7D7;
  }
  &.green {
    background: #00E13C;
  }
  &.brightred {
    background: #FF3333;
  }
  &.brightorange {
    background: #FFCC33;
  }
  &.brightgreen {
    background: #CCFF33;
  }
  &.brightblue {
    background: #33CCFF;
  }
}
.place-list-no-comment {
  position: relative;
  top: 12px;
}

.place-list-media-icon {
  float: left;
  margin-top: 7px;
  svg {
    float: left;
    width: 18px;
    height: 18px;
  }
}
.circle-counter-places {
  float: left;
  height: 16px;
  width: 16px;
  text-align: center;
  background-size: 100%;
  margin-left: 5px;
  margin-top: 1px;
  background-image: url("../images/circle.svg");
  p {
    font-size: 11px;
    font-weight: bolder;
  }
}

// .places-wrap {
//   overflow-y: scroll;
//   height: 100%;
//   position: absolute;
//   width: 280px;
// }

.hazard-level-label {
  text-align: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  &.list-label-hazard {
    top: -1px;
    right: -1px;
    .hazard-level-4 {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
    }
    .hazard-level-3 {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
    }
    .hazard-level-2 {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
    }
    .hazard-level-1 {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
    }
  }
  .hazard-level-4 {
    background-color: #3cf;
    border-radius: 12px;
    padding: 3px 10px;
    display: inline-block;
    font-size: 12px;
    font-family: 'Roboto';
    &.large-label {
      border-radius: 14px;
      padding: 5px 11px;
      font-size: 14px;
    }
  }
  .hazard-level-3 {
    background-color: #cf3;
    border-radius: 12px;
    padding: 3px 10px;
    display: inline-block;
    font-size: 12px;
    font-family: 'Roboto';
    &.large-label {
      border-radius: 14px;
      padding: 5px 11px;
      font-size: 14px;
    }
  }
  .hazard-level-2 {
    background-color: #fc3;
    border-radius: 12px;
    padding: 3px 10px;
    display: inline-block;
    font-size: 12px;
    font-family: 'Roboto';
    &.large-label {
      border-radius: 14px;
      padding: 5px 11px;
      font-size: 14px;
    }
  }
  .hazard-level-1 {
    background-color: #f33;
    border-radius: 12px;
    padding: 3px 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: bolder;
    color: #fff;
    font-family: 'Roboto';
    &.large-label {
      border-radius: 14px;
      padding: 5px 11px;
      font-size: 14px;
    }
  }
}
.taisaku-place-label-modal {
  background-color: #0e6aad;
  color: #fff;
  padding: 3px 10px;
  border-radius: 12px;
  display: inline-block;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: bolder;
  float: left;
  margin: 0 5px;
}
.public-place-label-modal {
  background-color: #33ccff3d;
  color: #0e6aad;
  padding: 3px 10px;
  border-radius: 12px;
  display: inline-block;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: bolder;
  float: left;
  margin: 0 0 0 5px;
}
.public-place-label {
  position: absolute;
  bottom: 9px;
  right: 5px;
  z-index: 9;
  background-color: #33ccff3d;
  color: #0e6aad;
  padding: 3px 10px;
  border-radius: 12px;
  display: inline-block;
  font-size: 10px;
  font-family: 'Roboto';
  font-weight: bolder;
}
.has-read-label {
  float: left;
  margin-top: 13px;
  margin-left: 5px;
  position: absolute;
  left: 45px;
  bottom: 15px;
  h3 {
    z-index: 9;
    color: #ffffff;
    font-size: 8px;
    font-family: 'Roboto';
    float: left;
  }
  .modal-read-label {
    color: #9c9c9c;
    font-size: 8px;
    font-family: 'Roboto';
  }
}
.taisaku-label {
  &.is-public-true {
    right: 48px;
  }
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 9;
  background-color: #0D6AAD;
  color: white;
  padding: 3px 8px;
  display: inline-block;
  font-size: 10px;
  font-family: 'Roboto';
  border-radius: 12px;
}
span.amount-per-page-label {
  float: right;
  color: #585858;
  font-size: 11px;
  margin: 9px 10px 0 10px;
}
span.places-found {
  color: #585858;
  font-size: 12px;
  width: 100%;
  text-align: center;
}
.places-found-container {
  float: left;
  width: 100%;
  height: 56px;
  background-color: #e8e8e8;
  padding: 3px 0px;
  margin-bottom: 5px;
}
.amount-per-page-action-container {
  float: left;
  width: 100%;
  margin-top: -5px;
  background-color: #e8e8e8;
  margin-bottom: 1px;
  padding: 10px 10px 10px 5px;
  &.is-live {
    width: calc(100% + 10px);
    margin-top: -8px;
  }
  .place-select-menu-container {
    text-align: center;
  }
  .select-query-amount {
    float: right;
    margin-right: 0;
  }
  form {
    float: right;
    display: block;
    position: initial;
    margin-right: 20px;
  }
}
.place-list-tabs-container {
  margin-bottom: 5px;
  width: 100%;
}
label.left-label-list {
  position: absolute;
  left: -60px;
  top: 10px;
}
form div > div.input-margin {
  margin-top: 0;
}
button.load-more-btn {
  float: left;
  width: 100%;
  margin: 20px 0px;
  color: #fff;
  background-color: #0E6AAD;
  &.disabled {
    background-color: #ddd;
    padding: 10px 0;
  }
  &:hover {
    background-color: #148ce4;
    color: #fff;
  }
}
.place-list-checkbox-container {
  z-index: 9999;
  position: absolute;
  left: 5px;
  right: 0;
  top: 0;
  bottom: 0;
  > span:first-child {
    top: 0;
    bottom: 0;
    position: absolute;
    height: 24px;
    width: 24px;
    margin: auto;
  }
}
.place-service-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  button {
    background-color: #ffffff;
    color: #d89619;
    border: 2px solid #d89619;
    box-shadow: none;
    &:hover {
      background-color: #d89619;
      color: #fff;
    }
  }
}
.delete-place-modal > div > div:first-child {
  max-width: 500px;
  width: 100%;
  display: flex;
  padding: 20px;
  .header {
    margin-bottom: 5px;
  }
  .body {
    margin: 10px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
.duration-stream {
  color: #fff;
  font-family: 'Roboto', helvetica;
  font-size: 12px;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  max-width: 47px;
  margin: auto;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bolder;
  background-color: #0000006b;
  padding: 2px 0 3px;
  border-radius: 15px;
}