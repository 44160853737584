.print-view-wrap {
  display: none;
  position: relative;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  .hazard-level-label {
    -webkit-print-color-adjust: exact;
  }
}
.print-modal-place-single {
  .hazard-level-label {
    -webkit-print-color-adjust: exact;
    margin-top: -5px;
    p {
      padding: 0;
      margin: 0;
      font-size: 4vw;
      width: 100%;
    }
  }
}
.place-photo-grid {
  float: left;
  width: 100%;
  height: 400px;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 2px solid #d1d1d1;
  img {
    height: 100%;
    max-height: 400px;
    object-fit: contain;
    background-color: #ececec;
    object-position: center;
    -webkit-print-color-adjust: exact;
  }
}
.print-single-place {
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #fff;
  page-break-after: always;
  .photo-amount {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .hazard-level-label {
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    .hazard-level-4 {    
      border-radius: 28px;
      padding: 5px 20px;      
      font-size: 40px;
    }
    .hazard-level-3 {    
      border-radius: 28px;
      padding: 5px 20px;      
      font-size: 40px;
    }
    .hazard-level-2 {    
      border-radius: 28px;
      padding: 5px 20px;      
      font-size: 40px;
    }
    .hazard-level-1 {    
      border-radius: 28px;
      padding: 5px 20px;      
      font-size: 40px;
    }
  }
  .place-list-item-date {
    float: none;
    position: relative;
    width: 100%;
    h3 {
      float: none;
      font-size: 40px;
    }
  }
  .place-list-item-group-name {
    float: none;
    h4 {
      float: none;
    }
  }
  .place-modal-info h4 {
    font-size: 44px;
  }
  .place-list-item-username-container {
    float: none;
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    svg {
      float: none;
      width: 54px;
      height: 54px;
      display: inline-block;
      vertical-align: middle;
    }
    h5 {
      float: none;
      font-size: 54px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .place-list-item-coordinates {
    float: none;
    position: relative;
    width: 100%;
    margin-top: 5px;
    &.large-coords {
      display: flex;
      flex-direction: row;
      div {
        margin-right: 20px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .place-modal-info h5 {
    font-size: 44px;
  }
  .place-print-single-photos {
    img {
      display: block;
      margin: 20px auto;
      height: 690px;
      max-height: 690px;
    }
    &.no-comment {
      img {
        height: 810px;
        max-height: 810px;
      }
    }
  }
}
.print-multi-place {
  display: block;
  .place-grid-two-by-two {
    float: left;
    width: 50%;
    border: 3px solid #fff;
  }
  .place-list-item-group-name {
    h4 {
      font-size: 15px;
    }
  }
  .photo-amount {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .place-list-item-username-container {
    margin-bottom: 0;
  }
  .place-list-item-coordinates {
    float: left;
    position: relative;
    width: 100%;
    margin-top: 2px;
    p {
      font-size: 9px;
    }
  }
}
.place-modal-right-col.print-modal-place-multi {
  float: left;
  display: block;
  width: 100%;
  height: 115px;
  border: 2px solid #ddd;
  border-top: 3px solid #5994ff;
  .hazard-level-label {
    top: 15px;
    right: 15px;
  }
}
.print-modal-place-multi .place-modal-info {
  display: block;
  position: relative;
  width: 100%;
  padding: 8px;
  float: left;
}
.place-date-and-tag-container {
  display: flex;
  .hazard-level-label.print-hazard-level {
    width: 330px;
    overflow: hidden;
    float: none;
    justify-content: flex-end;
    display: flex;
    p {
      white-space: nowrap;
      font-size: 3.3vw;
    }
  }
}
.print-modal-place-single .place-modal-info {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  h5.place-list-item-notes {
    float: none;
    position: relative;
    height: 116px;
  }
}
@media print {
  #customized-menu,
  .place-select-confirm-modal,
  .places-list-column,
  .hazard-map-column,
  .place-modal,
  .filter-list-column {
    display: none;
  }
  div.print-view-wrap {
    display: block!important;
    z-index: 99999;
    height: 100%;
    &.single-place {
      overflow: scroll;
    }
    &.disable-scrollbars {
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }
    }
    .print-single-place {
      page-break-after: always;
    }
  }
}
