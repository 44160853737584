.hazard-live-screen-builder {
  position: fixed;
  top: calc(-100% + 40px);
  left: 0;
  width: calc(100% - 280px);
  z-index: 991;
  margin: auto;
  height: calc(100% - 40px);
  background-color: #000;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0;
  transition: all 0.5s ease-in-out;
  &.opened-builder {
    top: 0;
     &.expand {
      width: 100%;
    }
  }
  button.monitor-dropdown-btn {
    width: 165px;
    color: #fff;
    background-color: #1b1b1b;
    border-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
    padding: 8px 10px 8px 10px;
    position: absolute;
    right: 0;
    margin: auto;
    bottom: -40px;
    z-index: 3;
    box-shadow: 0px -6px 21px 0px rgba(0, 0, 0, 0.23);
    &.opened {
      right: 50px;
    }
    &:hover {
      background-color: #000;
    }
    img {
      width: 17px;
      margin-right: 6px;
      margin-bottom: 1px;
    }
  }
}
.monitor-mode-navbar {
  float: left;
  width: 100%;
  height: 50px;
  .logo svg {
    float: left;
    width: 189px;
    margin: 20px 0 0 27px;    
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#F21439;}
    .st2{fill:#F21439;}
  }
}
button.close-monitor {
  position: absolute;
  right: 23px;
  top: 11px;
  bottom: 0;
  color: #ddd;
  height: 35px;
  width: 35px;
  background-color: #2b2b2b;
  border-radius: 50%;
  min-width: initial;
  min-height: initial;
  &:hover {
    background-color: #464344;
  }
  svg {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
button.remove-from-monitor {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: translateY(-35px);
  position: absolute;
  left: 2px;
  top: 2px;
  color: #ddd;
  height: 25px;
  width: 25px;
  background-color: #464344;
  border-radius: 50%;
  min-width: initial;
  min-height: initial;
  z-index: 9;
  &:hover {
    background-color: #ff0a40;
  }
  svg {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.monitor-grid-container {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  height: calc(100% - 50px);
  width: 100%;
  padding: 0 5px 5px 5px;
  z-index: 9;
  background-color: #000;
  h5 {
    color: #fff;
    font-size: 15px;
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
  .monitor {
    float: left;
    width: calc(33.3333333333% - 4px);
    height: calc(50% - 5px);
    border: 2px dashed #353535;
    border-radius: 10px;
    position: relative;
    margin: 2px;
    overflow: hidden;
    &.has-place {
      border: 2px solid #000;
      &:hover {
        button.remove-from-monitor {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
    &.running {
      border: 2px solid #ff1957;
      overflow: hidden;
    }
  }
  .monitor-active {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: absolute;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .play-icon {
    max-width: 50px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
button.monitor-create-btn {
  margin: auto;
  background-color: #0D6AAD;
  color: #fff;
  padding: 10px 20px;
  display: block;
  margin-top: 20px;
  z-index: 3;
  &:hover {
    background-color: #129afd;
  }
}
.storage-video-monitor {
  float: left;
  width: 100%;
  height: calc(100% - 85px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  video {
    width: 100%;
    max-height: 100%;
    float: left;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}
.livestream-monitor-place {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0e0e0e;
  color: #fff;
  padding: 10px;
  height: 85px;
  border-top: 1px solid #3e3e3edd;
  z-index: 2;
  h4.place-list-item-group-name {
    color: #0094ff;
  }
  .place-list-item-username-container {
    h5 {
      margin: 3px;
      font-size: 10px;
      float: left;
      width: initial;
      font-weight: 400;
    }
  }
  .place-list-item-date {
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: right;
    padding: 5px 5px;
    h3 {
      color: #6f6f6f;
      font-size: 10px;
    }
  }
}
.livebar {
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 100%;
  height: 66px;
  background-color: #000;
  background-image: url('../images/hazardlive-banner.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0;
  transition: all 1s ease-in-out;
  display: none;
  h5 {
    color: #fff;
    position: absolute;
    bottom: -30px;
    font-size: 13px;
    left: 11px;
  }
  &.opened {
    display: block;
    opacity: 1;
    z-index: 1;
  }
  .livebar-hook {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 26px;
    width: 100%;
    background-color: #1b1b1b;
    border-bottom-left-radius: 30px;
  }
}
.live-screen-side-bar-actions {
  background-color: #333;
  position: absolute;
  right: 0;
  bottom: 0;
  button {
    float: right;
    background-color: #FFC107;
    text-transform: none;
    width: 51px;
    height: 40px;
    min-width: initial;
    border-radius: 0;
    &:hover {
      background-color: #ff7c07;
    }
    svg {
      transform: rotate(0deg);
    }
    &.opened {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
