@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
button.upload-start-button-icon {
  float: left;
  width: 100%;
  position: relative;
  border: 3px dashed #ddd;
  border-radius: 20px;
  height: 100px;
  overflow: hidden;
  &:hover {
    border-color: #1e5ff1;
    .drop-upload-icon {
      color: #1e5ff1;
    }
  }
  &.uploading-drop-photo {
    border: 3px solid #33a0ff;
    color: #33a0ff;
    .drop-upload-icon {
      color: #1e5ff1;
      position: absolute;
      left: -145px;
      right: 0;
      margin: auto;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      -webkit-animation-name: hvr-bob-float, hvr-bob;
      animation-name: hvr-bob-float, hvr-bob;
      -webkit-animation-duration: .3s, 1.5s;
      animation-duration: .3s, 1.5s;
      -webkit-animation-delay: 0s, .3s;
      animation-delay: 0s, .3s;
      -webkit-animation-timing-function: ease-out, ease-in-out;
      animation-timing-function: ease-out, ease-in-out;
      -webkit-animation-iteration-count: 1, infinite;
      animation-iteration-count: 1, infinite;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-direction: normal, alternate;
      animation-direction: normal, alternate;
    }
  }
  .drop-upload-icon {
    z-index: 3;
  }
  &.uploading-started {
    border: 3px solid #ddf5e9;
    &:hover {
      border: 3px solid #ddf5e9;
    }
  }
}
.state-of-upload-title {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

svg.rotate-arrow {
  transform: rotate(90deg);
  height: 15px;
  width: 15px;
  margin-right: 5px;
}
.photo-uploading-button-icon {
  width: calc(33.33333333% - 4px);
  margin: 2px;
  padding-bottom: 20%;
  border-radius: 20px;
  float: left;
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 3px solid #ddf5e9;
  .photo-upload-started svg{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}