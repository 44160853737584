.search-while-map-moves {
  position: fixed;
  top: 0;
  right: 290px;
  background-color: #fff;
  margin: auto;
  z-index: 9;
  max-width: 210px;
  padding-left: 15px;
  text-align: center;
  border-radius: 10px;
  top: 8px;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #e8e8e8;
  span {
    font-size: 13px;
    font-weight: bolder;
    white-space: nowrap;
  }
}
.map-styles-container {
  position: absolute;
  display: flex;
  z-index: 1;
  right: 0;
  bottom: 46px;
  left: 100px;
  visibility: hidden;
  opacity: 0;
  width: 200px;
  &.slide-true {
    visibility: visible;
    opacity: 1;
    transition: 1s;
    left: 117px;
  }
  &.slide-false {
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    left: 100px;
  }
  .map-button {
    display: block;
    height: 43px;
    width: 43px;
    z-index: 99;
    background-color: #ffffff;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    margin-left: 5px;
    background-size: cover;
    border: 3px solid #fff;
    transition: all 0.4s ease-in-out;
    &:hover {
      cursor: pointer;
      border-color: #0E6AAD;
      transform: translate3d(0px, -2px, 2px) scale(1.05);
    }
    &.simple {
      background-image: url("../images/simple-style.jpg");
    }
    &.satellite {
      background-image: url("../images/satellite-style.png");
    }
    &.terrain {
      background-image: url("../images/terrain-style.jpg");
    }
    &.hazard {
      background-image: url("../images/hazard-style.jpg");
    }
  }
}
.loading-last-location-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9;
}
.tooltip-loading-last-locations {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 108px;
  background-color: rgba(0, 0, 0, 0.64);
  border-radius: 5px;
  max-width: 241px;
  padding: 10px;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: rgba(0, 0, 0, 0.64) transparent transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -10px;
  }
  p {
    color: #fff;
    text-align: center;
  }
}
button.push-notify-lastlocation-devices {
  position: absolute;
  z-index: 999;
  bottom: 45px;
  left: -150px;
  right: 0;
  margin: auto;
  background-color: #0f6aad;
  border: 2px solid #34ccff;
  color: #fff;
  padding: 8px 20px;
  border-radius: 45px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  &.display-extra-btn  {
    opacity: 1;
    width: 157px;
    height: 44px;
    min-width: 157px;
  }
  &.custom-message {
    opacity: 1;
    bottom: 45px;
    right: 0;
    left: 0;
    padding: 0;
    height: 45px;
    width: 45px;
    min-width: 45px;
    &.offset-left-center {
      right: -76px;
    }
    &:hover {
      background-color: #148ce4;
    }
    &.hide-button {
      display: none;
    }
    svg {
      margin: 0;
      max-width: 20px;
    }
  }
  &.loading-request {
    padding: 8px;
    min-width: 50px;
    left: 0;
    span > div {
      color: #fff;
    }
    svg {
      margin: initial;
      fill: #fff;
      color: #fff;
    }
  }
  svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: #148ce4;
  }
}