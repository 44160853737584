.hazard-settings-wrapper {
  margin: 10px 35px;
  .setting-tag-container {
    .setting-tag-header {
      padding: 10px 0px;
    }
  }
  
}
.hazard-level-form-wrapper {
  margin: 10px 35px;
  .hazard-level-form-container {
    .hazard-level-form-title {
      padding: 10px 0px;
    }
    .hazard-level-form-container {
      .hazard-level-form {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
      .save-hazardlevel-name-btn {
        text-align: center;
        padding-bottom: 20px;
      }
    }
  }
}

.setting-tag-btn-list-container {
  text-align: center;
  .setting-tag-btn {
    margin: 2px;
    margin-bottom: 20px;
  }
  .btn-grid-wrap {
    display: inline-grid;
    &.dark-mode {
      button {
        background-color: #1471f2!important;
      }
    }
  }
}

.hazard-level {
  padding: 20px;
}

.default-level-1 {
  height: 15px;
  width: 5px;
  background-color: #F33;
  margin-right: 10px;
}
.default-level-2 {
  height: 15px;
  width: 5px;
  background-color: #FC3;
  margin-right: 10px;
}
.default-level-3 {
  height: 15px;
  width: 5px;
  background-color: #CF3;
  margin-right: 10px;
}
.default-level-4 {
  height: 15px;
  width: 5px;
  background-color: #3CF;
  margin-right: 10px;
}

.hazard-name-1 {
  margin: 0;
  background-color: #f33;
  border-radius: 12px;
  padding: 3px 10px;
  display: inline-block;
  font-size: 12px;
  font-weight: bolder;
  color: #fff;
  font-family: 'Roboto';
}
.hazard-name-2 {
  margin: 0;
  background-color: #fc3;
  border-radius: 12px;
  padding: 3px 10px;
  display: inline-block;
  font-size: 12px;
  font-family: 'Roboto';
}
.hazard-name-3 {
  margin: 0;
  background-color: #cf3;
  border-radius: 12px;
  padding: 3px 10px;
  display: inline-block;
  font-size: 12px;
  font-family: 'Roboto';
}
.hazard-name-4 {
  margin: 0;
  background-color: #3cf;
  border-radius: 12px;
  padding: 3px 10px;
  display: inline-block;
  font-size: 12px;
  font-family: 'Roboto';
}
.language-setting {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 20px 20px;
}
.language-setting-btn {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ddd;
  height: 30px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 5px;
  user-select: none;
  font-size: 15px;
}
.language-setting-btn:hover {
  background-color: #168ee5;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.language-setting-btn.selected {
  background-color: #0e6aad;
  color: #fff;
}