.HAZARDLIVE_APP {
  .hazard-logo {
    position: absolute;
    left: 5%;
    width: 126px;
    top: 21%;
  }
}
.edit-button.rotate.rotate-right.mic-on {
  background-color: black;
  &:hover {
    border-color: red;
    svg {
      fill: #00ff37;
    }
  }
  svg {
    fill: #00ff37;
  }
}
.edit-button.rotate.rotate-right.mic-off {
  border-color: red;
  &:hover {
    border-color: #00ff37;
    svg {
      fill: #00ff37;
    }
  }
  svg {
    fill: red;
  }
}
.group-select-notify-modal.dark-mode-dialog > div:nth-child(2) > div:first-child {
  background-color: #000;
  button.close-notification-dialog {
    position: absolute;
    z-index: 9999;
    background-color: #ffed00;
    right: 0;
    height: 49px;
    width: 49px;
    min-width: 0;
    border-radius: 0;
    color: #000;
  }
  span.group-name-list-title {
    background-color: #2d2d2d;
  }
  span.no-users-in-group-for-msg {
    background-color: #0e0e0e;
  }
  .sending-notification {
    background-color: #000;
    svg {
      color: #da0a0a;
    }
  }
  .tabs-wrap-notifications {
    background-color: #0c0c0c;
    border-bottom: 1px solid #5d5d5d;
    button {
      span {
        color: #fff;
      }
    }
    > div > div > div > span:last-child {
      background-color: #ff003b!important;
    }
  }
  .notify-group-form legend {
    background-color: #000;
    color: #fff;
    border-left: 1px solid #444;
  }
  .notify-group-form {
    span {
      color: #fff;
    }
    svg {
      color: #ffed00;
    }
  }
  .tab-notification-panel-wrap {
    background-color: #0e0e0e;
    border-right: 1px solid #444;
    h2 {
      color: #fff;
    }
    input {
      color: #fff;
    }
    textarea {
      color: #fff;
    }
  }
  .history-record {
    border: 0;
    background-color: #2d2d2d;
    color: #fff;
    z-index: 9;
    .msg-content {
      border-bottom: 1px solid #444;
    }
    a {
      color: #ff003b;
    }
    p {
      color: #fff;
    }
    span {
      color: #888;
    }
  }
  .form-wrapper-notifications button.sending-notification-btn.disabled-send-btn {
    background-color: #383838;
  }
  .form-wrapper-notifications button.sending-notification-btn {
    background-color: #1471f2;
  }
  .dark-mode-outline-input {
    label {
      color: #fff;
    }
    fieldset {
      border-color: #808080;
    }
  }
  .dark-mode-outline-textarea {
    label {
      color: #fff;
    }
    fieldset {
      border-color: #808080;
    }
  }
}
.hazardCameraVideo {
  width: 100%;
  height: 500px;
  position:relative;
  z-index: 3;
}
.hc-title-bar {
  .hc-user-name svg {
    margin-bottom: -6px;
    position: relative;
  }
  .is-live-tag-list {
    color: #fff;
    float: left;
    clear: both;
    margin-bottom: 5px;
    font-size: 11px;
    transform: scale(1.21);
    margin-left: 3px;
  }
  .list-label-hazard {
    right: auto;
    left: 80px;
    top: 7px;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    .hazard-level-label {
      border-radius: 0;
      font-size: 14px;
      padding: 2px 10px;
      text-align: center;
    }
  }
}
.hc-group-name {
  display: block;
  color: #0094ff;
  margin: 0;
  float: left;
  clear: both;
  font-family: 'Roboto', 'Helvetica';
}
p.hc-user-name {
  color: #fff;
  display: block;
  float: left;
  clear: both;
  margin: 0;
  font-family: 'Roboto', 'Helvetica';
}
.loading-hc-stream {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - 70px);
  display: block;
  margin: auto;
  text-align: center;
  background-position: center;
  background-size: cover;
  z-index: 2;
  .loading-hc-selection {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 50px;
    width: 50px;
    left: -15px;
    right: 0;
    svg {
      color: #0062eb;
    }
  }
}
.group-select-notify-modal.dark-mode-dialog.hazardcam-dialog > div:nth-child(2) > div:first-child {
  overflow-x: hidden;
  a,b,strong,h1,h2,h3,h4,h5 {
    color: #fff;
    font-family: 'Roboto', 'Helvetica';
  };
  .hc-title-bar {
    width: 100%;
    border-bottom: 2px solid #ffd100;
    background-color: #000;
    padding: 10px;
    position: relative;
    min-height: 50px;
    z-index: 3;
    h2, h1 {
      margin: 0;
    }
  }
  .close-notification-dialog.close-hc-stream {
    top: 0;
    height: 100%;
    min-height: 50px;
  }
}
.dark-mode-menu {
  > div:nth-child(2) > div {
    background-color: #080808;
    .dialog-nav-container {
      background-color: #151515;
      border: 0;
      button {
        background-color: #1471f2!important;
      }
    }
    h5 {
      color: #fff;
    }
    .hazard-level {
      > div > div > div {
        fieldset {
          border-color: #ddd;
        }
        input {
          color: #fff;
        }
      }
    }
    .hazard-level-form-container {
      background-color: #151515;
      button {
        background-color: #1471f2!important;
      }
    }
    .cat-drill-down-list-container {
      margin: 10px 35px;
      table {
        border: 1px solid #000;
        th {
          color: #FFF;
        }
        tr {
          border-bottom: 1px solid #808080;
        }
      }
      > div {
        background-color: #151515;
      }
      button {
        background-color: #1471f2!important;
      }
      .form-map {
        button {
          background-color: #fff !important;
        }
      }
      .add-group-form-container {
        border: 1px solid #000;
        p, h6 {
          color: #FFF;
        }
        fieldset {
          border-color: #ddd;
        }
        input {
          color: #fff;
        }
        label {
          color: #fff;
        }
      }
    }
  }
}
.delete-place-modal.dark-mode {
  overflow: hidden;
  > div > div:first-child {
    background-color: #000;
    color: #fff;
    overflow: hidden;
    border: 2px solid #ddd;
    hr {
      background-color: #3c3c3c;
    }
    h6,p {
      color: #fff;
      margin-left: 0;
    }
  }
}
.hazard-settings-wrapper.is-live > div {
  background-color: #000;
}

.HAZARDLIVE_APP {
  .disable-filters-list {
    background-color: rgba(0,0,0,0.8);
  }
  .portal-wrap {
    z-index: 99999;
  }
  .windows_wrapper {
    .filters-main-container {
      .scroll-hidden {
        background-color: #1b1b1b;
      }
    }
  }
  .login-form-logo-container {
    background-color: #000;
  }
  .login-page-container {
    background-color: #111213;
    background-image: url('../images/hv-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    > div:first-child {
      background-color: #0e0e0e;
    }
  }
  button.btn-primary {
    background-color: #1471f2
  }
  .navbar-container {
    background-color: #000;
  }
  .user-info-container {
    background-color: #111213;
  }
  .filter-list-column {
    background-color: #1b1b1b;
  }
  .date-filter-buttons button span {
    color: rgb(125, 101, 14);
  }
  .loading-map {
    background-color: #000;
    background-image: url('../images/loading-bg-dark.jpg');
    .loading-map-spinner-container {
      background-color: #000;
    }
    .loading-map-spinner {
      color: #1471f2;
    }
  }
  .place-filter-section .place-filter-section-title {
    color: #ffcc33;
  }
  .place-filter-section .place-filter-divider {
    border: 0;
    border-top: 1px solid #3a3a3a;
  }
  .filter-area > div:first-child {
    background-color: #000;
  }
  .filter-area {
    color: #fff;
    span {
      color: #fff;
      svg {
        color: #fc3;
      }
    }
    p {
      color: #fff;
    }
    .expansion-panel-top .top-level-tag {
      color: #ffcc33;
    }
  }
  .picker-end input,
  .picker-start input {
    color: #fc3;
  }
  .places-list-column {
    background-color: #1b1b1b;
    &.dragging-to-builder {
      background-color: transparent;
    }
  }
  .amount-per-page-action-container {
    background-color: #130f0f;
  }
  .hazard-live-screen-builder button.monitor-dropdown-btn {
    background-color: #120e0e;
  }
  .hazard-live-screen-builder .logo svg{
    width: 150px;
    margin: 12px 0 0 27px;
  }
  .no-active-streams-currently {
    background-color: #000000;
    h3 {
      color: #ffffff;
    }
  }
  .resignin-user-loading {
    background-color: #111213;
    .loader-container svg {
      color: #1471f2;
    }
    .loader-container .loader-logo {
      background-image: url("../images/HazardLiveLogo-pb-c.svg");
    }
  }
  .monitor-grid-container .monitor.has-place {
    border: 2px solid #252525;
  }
  form {
    .dark-theme-label {
      color: #ddd;
    }
    .dark-theme-input {
      color: #fff;
      &:before {
        border-bottom: 1px solid #ddd;
      }
      &:after {
        border-bottom: 2px solid #ffc000;
      }
    }
  }
  button.load-more-btn {
    background-color: #1471f2;
    &:hover {
      background-color: #ad243b;
    }
  }
  h4.place-list-item-live-title {
    color: #fff;
    height: 37px;
    overflow: hidden;
  }
  h5.place-list-item-notes {
    color: #fff;
  }
  .live-stream-thumb,
  .place-thumb-image-container {
    height: 130px;
    margin: auto;
    margin-top: 4px;
  }
  .place-list-item-content.isLiveStream {
    margin-top: 0;
    &.has-title {
      padding-top: 5px;
    }
    &.no-title {
      padding-top: 24px;
    }
  }
  .show-video-list {
    .is-live-tag-list {
      color: #fff;
    }
    .amount-per-page-action-container {
      background-color: #242527;
      input, select, textarea { 
        color: #fff;
      }
      svg {
        color: #fff;
      }
      form > div:first-child > div:first-child {
        &:before {
          border-color: #828282;
        }
        &:after {
          border-color: #e2a708;
        }
        > div:first-child {
          color: #fff;
        }
      }
    }
    .span.amount-per-page-label {
      color: #d4d4d4;
    }
    li.list-item-container {
      background-color: #060606;
      border: 1px solid #252525;
      height: 150px;
    }
    h4.place-list-item-group-name {
      color: #0094ff;
    }
    .place-list-item-date h3{
      color: #fff;
      margin-top: 5px;
    }
    .place-list-item-username-container {
      h5 {
        color: #fff;
        width: calc(100% - 15px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        fill: #fff;
      }
    }
  }
}
// responsive patches
.live-stream-box  {
  .comment-live-stream-button {
    position: absolute;
    bottom: 40px;
    right: 20px;
    z-index: 9999;
  }
  .comment-text-window {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    width: 300px;
    right: 0;
    bottom: calc(100% + 10px);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 1px 19px rgba(63,81,181, 0.8);
    border: 2px solid #ddd;
    p {
      color: #fff;
      margin: 0;
      font-family: "Roboto";
    }
  }
  .top-layer-controls {
    border-bottom: 0;
  }
  .place-list-item-title {
    color: #fff;
    font-size: 19px;
    font-weight: bold;
    float: left;
    padding: 20px;
    border-left: 4px solid #d81818;
    margin-left: 20px;
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .rotate-and-full-controls {
    max-width: 100%;
    width: 100%;
    float: left;
    margin: 0;
    padding: 0 20px 0 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    button {
      margin-right: 10px;
      white-space: nowrap;
    }
    .edit-button.export {
      width: calc(11% - 10px);
      margin-right: 10px;
    }
    .edit-button.edit {
      width: calc(11% - 10px);
      margin-right: 0;
    }
    .edit-button.fullscreen-video {
      width: calc(15% - 10px);
      margin: 0;
      margin-right: 10px;
    }
    .edit-button.rotate.rotate-left {
      width: calc(6% - 10px);
    }
    .edit-button.rotate.rotate-right {
      width: calc(6% - 10px);
    }
    .edit-button.recording-true {
      width: calc(10% - 10px);
    }
    .edit-button.recording-false {
      width: calc(10% - 10px);
    }
    .edit-button.video-mute {
      width: calc(6% - 10px);
    }
    .edit-button.video-snapshot {
      width: calc(15% - 10px);
    }
    .edit-button.video-download{
      width: calc(15% - 10px);
      margin-left: 10px;
    }
    .edit-button.export,
    .edit-button.edit,
    .edit-button.fullscreen-video,
    .edit-button.rotate.rotate-left,
    .edit-button.rotate.rotate-right,
    .edit-button.recording-true,
    .edit-button.recording-false,
    .edit-button.video-mute,
    .edit-button.video-snapshot,
    .edit-button.video-download {
      display: block;
      position: relative;
      float: left;
      left: initial;
      right: initial;
      padding: 11px;
      max-width: 100%;
      min-width: initial;
    }
  }
}
.edit-group-member-modal.dark-mode,
.add-group-member-modal.dark-mode,
.remove-user-modal.dark-mode {
  > div > div:first-child {
    background-color: #000;
    overflow: hidden;
    border: 2px solid #ddd;
    h6, p {
      color: #fff;
    }
    input {
      color: #fff;
      background-color: #323232;
      border-radius: 3px;
    }
    label {
      color: #fff;
    }
  }
}
.edit-group-member-modal.dark-mode {
  button {
    margin: 5px;
  }
}
