* {
  box-sizing: border-box;
}
body {
  margin: 0;
}

/* removes Google maps X button from infoWindows */
.gm-style-iw-chr button {
  display: none!important;
}

//google maps override button in infowindow
.gm-style-iw + button {display: none;}

.filter-list-column {
  position: fixed;
  overflow: hidden;
  width: 270px;
  height: 100%;
  left: 0;
  -webkit-box-shadow: -9px 9px 23px 0px rgba(0,0,0,0.53);
  -moz-box-shadow: -9px 9px 23px 0px rgba(0,0,0,0.53);
  box-shadow: -9px 9px 23px 0px rgba(0,0,0,0.53);
  z-index: 9;
  .filter-list-overflow {
    float: left;
    width: calc(100% + 30px);
    left: -15px;
    padding: 0 15px;
    height: 100%;
    overflow: scroll;
    position: relative;
  }
}
.hazard-map-column {
  position: fixed;
  width: calc(100% - 550px);
  height: 100%;
  left: 270px;
  z-index: 8;
  &.closed {
    width: calc(100% - 270px);
  }
}
// .place-list-overflow-wrap {
  // float: left;
  // left: 0px;
  // padding: 0px 15px 0px 0px;
  // height: 100%;
  // width: 100%;
  // position: relative;
// }
.dragging-to-builder {
  .place-list-overflow-wrap  {
    .show-video-list.dark-mode {
      overflow: initial;
      .live-places-wrapper {
        overflow: initial;
      }
    }
  }
}
.places-list-column {
  position: fixed;
  width: 280px;
  height: 100%;
  right: 0;
  z-index: 9;
  background-color: #fff;
  transition: right 0.5s ease-in-out;
  -webkit-box-shadow: 9px 0px 23px 0px rgba(0,0,0,0.53);
  -moz-box-shadow: 9px 0px 23px 0px rgba(0,0,0,0.53);
  box-shadow: 9px 0px 23px 0px rgba(0,0,0,0.53);
  &.closed {
    right: -280px;
  }
  &.dragging-to-builder {
    width: 280px;
    height: 100%;
    z-index: 992;
    background-color: transparent;
  }
  .show-video-list {
    height: 100%;
  }
}
button.btn-primary {
  background-color: #0D6AAD;
  color: #fff;
  &:hover {
    background-color: #129afd;
  }
}
.loader-login-spinner {
  margin-top: 2px;
  margin-bottom: 2px;
}
button.login-btn {
  span {
    margin-top: -2px;
    margin-bottom: 1px;
  }
}
.icon-type-of-modal {
  float: left;
  width: 100%;
}
svg.icon-lock {
  width: 23px;
  position: relative;
  top: 5px;
  left: -4px;
}
.place-modal {
  > div > div:first-child {
    width: 100%;
    display: initial;
  }
  .place-modal-info {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 50%;
    padding: 15px;
    left: 0;
    width: 100%;
    .place-list-item-date {
      margin-top: 5px;
    }
    .has-read-label {
      margin: 10px 5px 0 5px;
      position: initial;
      p {
        font-size: 10px;
      }
    }
    .hazard-level-label {
      left: 15px;
      right: initial;
      top: -10px;
      position: initial;
      float: left;
      margin-bottom: 10px;
    }
  }
  .slide-show-stepper-buttons {
    background-color: transparent;
    button {
      background-color: rgba(255,255,255,0.8);
      &.is-disabled-btn {
        background-color: rgba(255,255,255,0.2);
      }
    }
  }
  .place-modal-left-col {
    width: 100%;
    float: left;
    max-width: 400px;
    overflow: hidden;
    img {
      width: 100%;
      float: left;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
  .place-modal-right-col {
    float: right;
    width: 100%;
    max-width: 200px;
    padding-left: 20px;
    overflow: hidden;
    position: absolute;
    right: 0;
    min-height: 533px;
  }
}
.video-column-wrapper {
  min-height: 533px;
  background-color: #000;
  width: 100%;
  position: relative;
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.circle-icon {
  position: relative;
  top: -2px;
  left: 3px;
}
.last-location-modal {
  > div > div:first-child {
    max-width: 450px;
    width: 100%;
    display: initial;
    overflow: hidden;
    padding: 20px;
  }
  &.dark-mode {
    > div > div:first-child {
      background-color: #000;
      border: 2px solid #ddd;
      h4 {
        color: #fff!important;
        font-size: 25px;
      }
      hr {
        background-color: #464646;
      }
      p {
        color: #fff;
      }
      button {
        color: #fff;
        background-color: #2c2c2c;
        margin-top: -5px;
        &:hover {
          color: #ddd;
          background-color: #484848;
        }
      }
    }
  }
}

button.close-modal-btn {
  float: right;
  min-width: 32px;
}
.loading-filter-list {
  margin: 20px auto;
  display: block;
}
.loading-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-image: url('../images/loading-bg.jpg');
  background-size: cover;
  background-position: center;
  .loading-map-spinner-container {
    width: 100px;
    height: 100px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.12);
  }
  .loading-map-spinner {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #0E6AAD;
  }
}
.no-map {
  background-image: none !important
}
.loading-place-list {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .loading-place-spinner {
    margin: auto;
    color: #0E6AAD;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.loading-filter-list {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  svg {
    color: #0E6AAD;
  }
}

.add-group-member-modal {
  > div > div:first-child {
    max-width: 800px;
    width: 100%;
    display: initial;
    height: 400px;
    padding: 20px;
  }
}

.table-row {
  &:hover {
    cursor: pointer;
  }
  &.active {
    background-color: #F5F5F5;
  }
}

.make-public-modal {
  > div > div:first-child {
    width: 100%;
    display: initial;
    padding: 20px;
    max-width: 660px;
  }
  &.single-header {
    > div > div:first-child {
      max-width: 550px;
    }
  }
}
h5.private-public-info-header {
  float: left;
  width: 100%;
}
.view-more-btn-places-only {
  padding: 5px;
}
.make-public-buttons {
  float: right;
  .confirm-button {
    background-color: "#0D6AAD"; 
    color: "white";
    box-shadow: initial;
    font-weight: 100;
    height: 40px;
  }
  .close-button {
    margin: 10px;
    background-color: #fff;
    border: 2px solid #0e6aad; 
    color: #0e6aad;
    box-shadow: initial;
    font-weight: 100;
    &:hover {
      color: #fff;
    }
  }
}

.place-list-buttons {
  position: absolute;
  right: 0;
  &.live-stream-single-buttons {
    z-index: 9;
  }
  .edit-button {
    margin: 10px;
    background-color: #fff;
    border: 2px solid #0e6aad; 
    color: #0e6aad;
    box-shadow: initial;
    font-weight: 100;
    &.rotate {
      border: 0;
      padding: 0;
      margin: 0;
      width: 40px;
      height: 40px;
      min-width: 30px;
      margin-left: 10px;
    }
    &:hover {
      color: #fff;
      background-color: #0e6aad;
    }
  }
  .tracking-btn {
    background-color: #000;
    border: 2px solid #3BE068;
    &.on {
      border: 2px solid #3BE068;
    }
    &.off {
      border: 2px solid rgb(255, 0, 59);
      &.disabled {
        border: 2px solid #b1b1b1;
        color: #b1b1b1;
        border-color: #b1b1b1;
      }
    }
    color: #FFF;
    border-radius: 14px;
    border-width: 4px;
    padding: 4px 10px;
    margin-right: 16px;
    &:hover {
      color: #fff;
      background-color: #000;
    }
    .tracking-status-container {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      &.on {
        .status-icon {
          color: #3BE068;
          font-size: 18px;
        }
      }
      &.off {
        .status-icon {
          color: rgb(255, 0, 59);
          font-size: 18px;
          &.disabled {
            color: #b1b1b1;
          }
        }
      }
      .tracking-status {
        &.on {
          color: #3BE068;
        }
        &.off {
          color: rgb(255, 0, 59);
        }
        &.disabled {
          color: #b1b1b1;
        }
        font-size: 12px;
      }
    }
  }
  .close-button {
    height: 60px;
    &.live {
      background-color: #151515;
    }
  }
}
.tracking-map-container {
  position: absolute;
  top: 60px;
  height: calc(100% - 155px);
  background-color: #000;
  background-size: cover;
  width: 40%;
  right: 0;
  .form-map {
    height: 100%;
    width: calc(100% - 5px);
    min-height: initial;
    min-width: initial;
    position: relative;
    z-index: 9;
    object-fit: contain;
    margin: initial;
    float: right;
  }
  .tracking-map-resize {
    width: 10px;
    background-color: grey;
    height: 100%;
    position: absolute;
    &:hover {
      background-color: lightgray;
      cursor: col-resize;
    }
  }
  &.hide-tracking-map {
    display: none;
  }
}

.edit-place-upper-container {
  width: 100%;
  float: left;
  position: relative;
  padding: 0 10px;
  @media(max-width: 991px) {
    padding-top: 10px;
  }
  .form-upper {
    width: 100%;
    height: 100%;
    float: left;
    .form-left {
      float: left;
      width: 70%;
      height: 100%;
      @media(max-width: 991px) {
        width: 100%;
      }
    }
    .delete-row {
      float: left;
      width: 100%;
      border-bottom: 4px solid #EBEBEB;
      @media(max-height: 666px) {
        border-bottom: 2px solid #EBEBEB;
      }
      h6 {
        display: inline-block;
      }
      .delete-place-btn {
        color: #ff094b;
        float: right;
        line-height: 32px;
        &.large-fill {
          background-color: #ff094b;
          color: #fff;
          padding: 5px 20px;
          border-radius: 7px;
          transition: all 0.2s ease-in-out;
          margin-bottom: 5px;
          box-shadow: 1px 2px 20px rgba(0, 0, 0, .1);
          &:hover {
            background-color: #fc6a91;
            cursor: pointer;
            color: #fff;
          }
        }
        &:hover {
          color: #fc6a91;
          cursor: pointer;
        }
      }
      .update-place-btn {
        color: green;
        float: right;
        line-height: 32px;
        margin-right: 10px;
        &.large-fill {
          background-color: green;
          color: #fff;
          padding: 5px 20px;
          border-radius: 7px;
          transition: all 0.2s ease-in-out;
          margin-bottom: 5px;
          box-shadow: 1px 2px 20px rgba(0, 0, 0, .1);
          &:hover {
            background-color: mediumseagreen;
            cursor: pointer;
            color: #fff;
          }
        }
        &:hover {
          color: mediumseagreen;
          cursor: pointer;
        }
      }
    }
  }
  .edit-photo {
    height: 100%;
    float: right;
    position: absolute;
    right: 5px;
    width: 29%;
    @media(max-width: 991px) {
      width: 100%;
      position: relative;
      margin-top: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
      background-color: #dcdcdc;
      border-radius: 10px;
    }
  }
}
.livestream-video {
  width: 100%;
  height: 450px;
  float: left;
  background-image: url('../images/incoming-stream.jpg');
  background-size: cover;
  background-position: center center;
  &.is-playing {
    background-image: initial;
    background-color: #000;
  }
}
.pending-stream-storage {
  width: 100%;
  height: 450px;
  float: left;
  background-image: url('../images/livestream-ended-large.jpg');
  background-size: cover;
  background-position: center center;
}
.is-live-tag {
  background-image: url('../images/live.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
  border-radius: 15px;
  padding-left: 20px;
  color: #fff;
  font-weight: bolder;
  position: absolute;
  font-family: 'Roboto', 'Helvetica';
  letter-spacing: 1px;
  font-size: 15px;
  margin: 10px;
  &.fw-stream {
    color: #000;
    top: 9px;
    left: 20px;
    border: 2px solid #ff074a;
    padding-right: 8px;
    padding-left: 22px;
    background-position: 4px;
  }
}
.is-storage-tag {
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
  border-radius: 15px;
  padding-left: 6px;
  padding-right: 6px;
  color: #0980ff;
  border: 2px solid #0980ff;
  font-weight: bolder;
  float: left;
  font-family: 'Roboto', 'Helvetica';
  letter-spacing: 1px;
  font-size: 15px;
  &.monitor-tag {
    position: initial;
    float: left;
    color: #fff;
    margin: 0;
    user-select: none;
  }
}
.is-live-tag-list {
  color: #000;
  background-image: url('../images/live.svg');
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 11px;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 6px;
  font-weight: bolder;
  border: 2px solid #ff094b;
  float: left;
  font-family: 'Roboto', 'Helvetica';
  letter-spacing: 1px;
  font-size: 11px;
  &.monitor-tag {
    position: initial;
    float: left;
    color: #fff;
    margin: 0;
    user-select: none;
  }
}
.stream-place-view-created-at {
  color: #fff;
  float: left;
  font-family: 'Roboto', Helvetica;
  font-size: 13px;
  width: 100%;
  text-align: left;
  position: absolute;
  z-index: 9;
  bottom: 105px;
  left: 34px;
  z-index: 9999;
}
.duration-timestamps-large-player {
  font-family: 'Roboto', Helvetica;
  font-size: 13px;
  color: #fff;
  position: absolute;
  right: 20px;
  bottom: 10px;
  z-index: 9999;
}
.is-storage-tag-list {
  color: #0980ff;
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 11px;
  border-radius: 15px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bolder;
  border: 2px solid #0980ff;
  font-family: 'Roboto', 'Helvetica';
  letter-spacing: 1px;
  font-size: 11px;
  float: left;
  clear: both;
   &.monitor-tag {
    position: initial;
    float: left;
    margin: 0;
    font-size: 10px;
    user-select: none;
  }
}
.storage-video {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  width: 100%;
  margin-bottom: -4px;
  z-index: 999;
  position: relative;
}
.resignin-user-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  .loader-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 41px;
    height: 75px;
    .loader-logo {
      width: 150px;
      height: 50px;
      position: absolute;
      top: 56px;
      left: -54px;
      right: 0;
      margin: auto;
      background-image: url('../images/hazardview-logo-sm.svg');
      background-repeat: no-repeat;
    }
    svg {
      color: #ffc000;
      margin: auto;
      display: block;
    }
  }
}
.socket-is-closed-msg {
  position: absolute;
  height: calc(100% - 50px);
  width: 100%;
  background-color: #ececec;
  z-index: 9;
  top: 59px;
  background-image: url('../images/livestream-ended-large.jpg');
  background-size: contain;
  background-position: center;
}
.live-stream-box {
  &.rotate-90-neg {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(-90deg);
      left: 0;
      right: 0;
      position: absolute;
      margin: auto;
    }
  }
  &.rotate-90 {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(90deg);
      left: 0;
      right: 0;
      position: absolute;
      margin: auto;
    }
  }
  &.rotate-180 {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(180deg);
    }
  }
  &.rotate-180-neg {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(-180deg);
    }
  }
  &.rotate-270 {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(270deg);
      left: 0;
      right: 0;
      position: absolute;
      margin: auto;
    }
  }
  &.rotate-270-neg {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(-270deg);
      left: 0;
      right: 0;
      position: absolute;
      margin: auto;
    }
  }
  &.rotate-360 {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(360deg);
    }
  }
  &.rotate-360-neg {
    .stream-container > video,
    video.storage-video,
    video.livestream-video {
      transform: rotate(-360deg);
    }
  }
  .video-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    .loader-holder {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      margin: auto;
      background: none !important;
      svg {
        color: #ff0050;
      }
    }
  }
  .is-live-tag.fw-stream {
    font-size: 11px;
    background-size: 10px;
    padding: 1px 6px 1px 18px;
    float: left;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 0;
    position: initial;
  }
  .is-storage-tag.fw-stream {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 11px;
  }
  .hazard-level-label {
    right: initial;
    position: initial;
    float: left;
    margin: 8px;
    span {
      font-size: 11px;
      padding: 2px 10px;
    }
  }
  .place-list-item-username-container {
    width: auto;
    position: absolute;
    top: 34px;
    left: 17px;
  }
  .place-list-item-group-name {
    float: left;
    width: initial;
    margin: 11px 0 0 1px;
    font-size: 12px;
  }
}

.search-area-btn-border {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  cursor: pointer;
  margin: 5px 0px 0px 10px;
}

.search-area-btn-text {
  color: rgb(25,25,25);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
  padding-left: 5px;
  padding-right: 5px;
}

.search-area-btn-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; // Safari
  animation: spin 2s linear infinite;
}

.settings-btn-border {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  cursor: pointer;
  width: 100px;
  margin: 0px 0px 20px 10px;
}

.settings-btn-text {
  color: rgb(25,25,25);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
}

.settings-dropdown-border {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  cursor: pointer;
  width: 160px;
  margin-left: 10px;
  padding: 10px;
}

.show-dropdown-false {
  display: none;
}

.show-dropdown-true {
  display: block;
}

.settings-dropdown-text {
  color: rgb(25,25,25);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
  &:hover {
    background-color: rgb(216, 216, 216);
  }
}

// Safari 
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// frontend hacks
.windows_wrapper {
  .filter-list-column {
    .filter-list-overflow {
      width: calc(100% + 48px);
    }
  }
  .filters-main-container {
    .scroll-hidden {
      background-color: #fff;
    }
  }
  .place-list-overflow-wrap {
    width: 100%;
    height: calc(100% + 50px);
    padding-bottom: 50px;
  }
  .places-wrapper {
    padding-right: 3px;
  }
}
.no-active-streams-currently {
  float: left;
  width: 100%;
  background-color: #f3f3f3;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: 0;
  max-width: 270px;
  h3 {
    font-size: 15px;
    color: #b1b1b1;
  }
}
.live-streaming-modal {
  max-width: calc(100% - 280px);
  > div:first-child {
    width: calc(100% - 281px);
  }
  > div > div:first-child {
    background-image: url('../images/hazardlive-bg.jpg');
    background-size: cover;
    overflow: hidden;
  }
  .place-list-buttons {
    svg {
      color: #fff;
    }
  }
  .is-live-tag.fw-stream {
    color: #fff;
  }
  h4.place-list-item-group-name {
    color: #fff;
  }
  .place-list-item-username-container svg {
    color: #fff;
  }
  h5 {
    color: #fff;
  }
}
.live-stream-video-holder {
  position: absolute;
  top: 60px;
  height: calc(100% - 155px);
  background-color: #000;
  background-size: cover;
  width: 100%;
  &.realtime-tracking {
    width: 60%;
  }
  video.livestream-video {
    height: 100%;
    width: 100%;
    min-height: initial;
    min-width: initial;
    position: relative;
    z-index: 9;
    object-fit: contain;
    margin: initial;
  }
}
.stream-control-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 95px;
  background-image: url('../images/hazardlive-bg.jpg');
  background-size: cover;
  z-index: 1000;
  .seek-slider-controls {
    float: left;
    width: 100%;
    .play-icon-button {
      color: #fff;
      float: left;
      position: relative;
      padding: 4px;
      left: 10px;
    }
  }
}
.top-layer-controls {
  float: left;
  width: 100%;
  border-bottom: 1px solid #252525;
  border-top: 1px solid #131313;
}
.rotate-and-full-controls {
  max-width: 300px;
  margin: 10px auto;
  display: block;
  &.livestream-single-controls {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-button.bottom-bar {
    height: 50px;
    background-color: #000;
    border: 2px solid #404040;
    position: absolute;
    right: 20px;
    &:hover {
      border-color: #ffc107;
      color: #ffc107;
    }
    &.export.ja {
      padding-left: 0;
      padding-right: 0;
    }
    &.export span {
      white-space: break-spaces;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .edit-button.rotate.rotate-left,
  .edit-button.rotate.rotate-right {
    width: 50px;
    height: 50px;
    min-width: initial;
    background-color: #000;
    border: 2px solid #404040;
    &:hover {
      border-color: #ffc107;
      color: #ffc107;
    }
  }
  .edit-button.fullscreen-video {
    height: 50px;
    width: 180px;
    margin: 0 10px;
    background-color: #000;
    border: 2px solid #404040;
    &:hover {
      border-color: #ffc107;
      color: #ffc107;
    }
  }
  .edit-button.video-download {
    left: 20px;
  }
  .edit-button.video-snapshot {
    left: 150px;
  }
  .edit-button.video-mute {
    left: 326px;
  }
  .edit-button.recording-true,
  .edit-button.recording-false {
    left: 252px;
  }
  .edit-button.recording-true {
    svg {
      color: #00ff97;
    }
  }
  .edit-button.recording-false {
    svg {
      color: #ff0022;
    }
  }
  .edit-button.edit {
    right: 20px;
  }
  .edit-button.export {
    right: 90px;
  }
}
.pending-wrapper {
  overflow: hidden;
  border-radius: 20px;
  display: block;
  margin: auto;
  width: 100%;
  max-width: 350px;
  position: relative;
  top: 100px;
  left: -85px;
}

.pending-storage-placeholder {
  float: left;
  width: 100%;
  height: 290px;
  background-size: 400px;
  background-position: top;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url('../images/livestream-ended-large.jpg');
}
.pending-control-wrapper {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  .edit-button.bottom-bar {
    height: 50px;
    background-color: #000;
    border: 2px solid #404040;
    position: absolute;
    &:hover {
      border-color: #ffc107;
      color: #ffc107;
    }
  }
}
button.tab-public-private {
  color: #0e6aad;
  min-width: 50%;
  > span {
    position: absolute;
  }
}

.fullscreen-image-modal {
  > div > div:first-child {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
}

.live-snapshot-modal {
  > div > div:first-child {
    max-width: 605px;
    width: 100%;
    display: initial;
    padding: 20px;
  }
}
h6.live-snapshot-header {
  float: left;
  width: 100%;
  white-space: break-spaces;
}
.live-snapshot-buttons {
  float: right;
  margin: 10px 5px 5px 0;
  .confirm-button {
    background-color: "#0D6AAD"; 
    color: "white";
    box-shadow: initial;
    font-weight: 100;
    height: 40px;
    margin-left: 10px;
  }
  .close-button {
    background-color: #fff;
    border: 2px solid #0e6aad; 
    color: #0e6aad;
    box-shadow: initial;
    font-weight: 100;
    &:hover {
      color: #fff;
    }
  }
}
.snapshot-upload-spinner {
  margin: 2px 2px 2px 5px;
}
.dialog-transform-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.close-snapshot-modal {
  position: absolute;
  left: 90%;
  top: 5%;
  right: 0;
  bottom: 0;
}
.place-select-confirm-modal > div > div:first-child {
  max-width: 465px;
  width: 100%;
  display: initial;
  padding: 20px;
}
.place-select-incident-modal > div > div:first-child {
  max-width: 300px;
  width: 100%;
  display: initial;
  padding: 20px;
}
.incident-tag-form-container {
  word-break: break-all;
  text-align: left;
}
.creating-clip-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  z-index: 9999;
  .loader-holder {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 41px;
    width: 42px;
    span {
      color: #fff;
      position: absolute;
      left: -27px;
      right: 0;
      margin: auto;
      font-family: 'Roboto';
      font-size: 12px;
      top: 47px;
      width: 100px;
      text-align: center;
    }
  }
}

.zoom-level-select-modal > div > div:first-child {
  max-width: 265px;
  width: 100%;
  display: initial;
  height: 130px;
  padding: 20px;
  overflow: hidden;
}
h6.zoom-level-select-header {
  float: left;
  width: 100%;
  margin-bottom: 5px;
}
.default-zoom-container {
  float: left;
  position: relative;
  width: 100%;
  margin-top: 20px;
  .default-zoom-title {
    float: left;
    position: relative;
  }
  .default-zoom-select {
    min-width: 60px;
    position: relative;
    float: left;
    bottom: 22px;
    left: 20px;
  }
}
.default-location-container {
  float: left;
  width: 100%;
  min-height: 155px;
  .default-location-text {
    margin-top: 10px;
  }
}
.initial-location-modal > div > div:first-child {
  max-width: 600px;
  width: 100%;
  display: initial;
  max-height: 100%;
  padding: 20px;
  overflow-y: scroll;
}
.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.controls-edit-place {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.pac-marker-coords-container {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.marker-coords {
  margin: 0;
  line-height: 32px;
}
#pac-input {
  background-color: #fff;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 300;
  margin-right: 10px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 350px;
  border-radius: 5px;
  position: fixed;
  left: 280px;
  top: 0;
  z-index: 9999;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;
}
#pac-input:focus {
  border-color: #4d90fe;
  margin-bottom: 5px;
}
#pac-input-edit-place {
  background-color: #fff;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 300;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 200px;
  border-radius: 5px;
  margin-left: 10px;
}
#pac-input-edit-place:focus {
  border-color: #4d90fe;
  margin-bottom: 5px;
}
#pac-marker-coords-container  {
  background-color: #fff;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 300;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  max-width: 600px;
  border-radius: 5px;
  margin-right: 10px;
}
.pac-container {
  font-family: 'Roboto';
}
#type-selector {
  color: #fff;
  background-color: #4d90fe;
  padding: 5px 11px 0px 11px;
}
#type-selector label {
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 300;
}
table.responsive-side-table {
  width: 100%;
  display: block;
  float: left;
  padding: 20px;
  border: 1px solid #ddd;
  tbody {
    float: left;
    width: 100%;
    display: block;
    tr {
      border-bottom: 1px solid #ddd
    }
    td:nth-child(2) {
      width: 100px;
      padding: 6px;
      float: left;
      border-bottom: 0;
    }
    td:nth-child(3) {
      width: 139px;
      padding: 6px 0;
      border-bottom: 0;
    }
    th {
      float: left;
      width: 50%;
      border-bottom: 0;
      padding: 15px;
    }
  }
  thead {
    float: left;
    width: 100%;
    display: block;
    th:first-child {
      float: left;
      border-bottom: 0;
    }
  }
  tr {
    float: left;
    width: 100%;
    display: block;
  }
}
.hazard-cam-stream-wrap {
  padding: 20px;
  float: left;
  width: 100%;
}
.hazardcam-livestream {
  float: left;
  width: 100%;
  &.viewable-false {
    display: none;
  }
}
.webrtc-stream-wrap,
.canvas-stream-wrap {
  position: absolute;
  height: calc(100% - 85px);
  width: 100%;
  background-color: #000;
}
.hazardcam-monitor-livestream {
  z-index: 2;
  float: left;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.stream-loading-monitor {
  height: 35px;
  width: 35px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  z-index: 1;
}
.hazard-cam-info-bar {
  float: left;
  width: 100%;
}
.place-list-item-content.isLiveStream.livestream-dialog-title {
  margin-left: 0;
}
.notify-group-form {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  width: 250px;
  border-left: 2px solid #0b3556;
  legend {
    font-size: 12px;
    padding: 10px 10px 10px 33px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-left: 1px solid #ddd;
    z-index: 2;
    height: 48px;
    padding: 20px 20px 20px 35px;
  }
  fieldset {
    padding: 20px;
    padding-top: 0;
    position: absolute;
    top: 49px;
    right: 0;
    bottom: 0;
    left: 0;
    height: calc(100% - 49px);
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }
  div {
    float: left;
    width: 100%;
    text-align: left;
    label {
      margin-left: 0;
      flex-direction: row;
      width: 100%;
    }
  }
}
.group-users-name-block {
  margin-bottom: 10px;
}
span.group-name-list-title {
  font-size: 12px;
  width: calc(100% + 40px);
  display: -webkit-flex;
  display: flex;
  background-color: #f3f3f3;
  border-left: 5px solid #005fff;
  color: #005fff;
  font-weight: bold;
  margin-left: -20px;
  font-family: "Roboto", Arial, sans-serif;
  padding: 5px 5px 5px 30px;
  background-image: url('../images/account-group.svg');
  background-repeat: no-repeat;
  background-position: 5px 2px;
  background-size: 18px;
}
span.no-users-in-group-for-msg {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #e4f2ff;
  margin-top: 10px;
  border-radius: 3px;
}
.tabs-wrap-notifications {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.tab-notification-panel-wrap {
  position: absolute;
  top: 49px;
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #ddd;
  width: calc(100% - 247px);
  height: calc(100% - 49px);
  overflow: scroll;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
  &.full-panel-size {
    width: 100%;
    overflow-y: scroll;
  }
}
.notify-group-form-messages {
  float: left;
  width: calc(100% - 248px);
  padding: 20px;
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
}
.disable-group-form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 9;
}
.group-select-notify-modal {
  > div:nth-child(2) > div:first-child {
    min-width: 800px;
    max-width: 100%;
    min-height: 500px;
  }
}
.no-history {
  float: left;
  width: 100%;
  background-color: #e4e8ea;
  padding: 10px 20px;
  border-radius: 50px;
  text-align: center;
  p {
    color: #808080;
  }
}
.sending-notification {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 5;
  .spinner-center {
    height: 30px;
    width: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    bottom: 0;
    margin: auto;
    &:before {
      content: 'sending...';
      position: absolute;
      left: -13px;
      right: 0;
      top: 40px;
      font-family: "Roboto", Arial, sans-serif;
      margin: auto;
      color: #949494;
      text-align: center;
    }
    svg {
      color: #ddd;
    }
  }
}
.form-wrapper-notifications {
  button.sending-notification-btn {
    background-color: #0D6AAD;
    color: #fff;
    text-transform: none;
    padding: 13px 20px;
    float: right;
    svg {
      margin-right: 10px;
    }
    &.disabled-send-btn {
      background-color: #f1f1f1;
      color: #a2a2a2;
    }
    &:hover {
      background-color: #129afd;
    }
  }
}
button.close-notification-dialog {
  position: absolute;
  z-index: 9999;
  background-color: #ddd;
  right: 0;
  height: 49px;
  width: 49px;
  min-width: 0;
  border-radius: 0;
  color: #000;
}
.disable-filters-list {
  position: absolute;
  z-index: 999;
  background-color: rgba(255,255,255,0.8);
  width: calc(100% - 30px);
  height: calc(100% - 75px);
  .loading-filter-selection {
    position: absolute;
    top: -75px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.custom-clustericon-1 {
  --cluster-color: #00a2d3;
}

.custom-clustericon-2 {
  --cluster-color: #ff9b00;
}

.custom-clustericon-3 {
  --cluster-color: #ff6969;
}

.hazard-cluster-bubble:after {
  content: "";
  position: relative;
  height: 8px;
  width: 8px;
  background: #FFF;
  display: block;
  margin-left: -6px;
  bottom: 8px;
  border: 2px solid;
  border-color: transparent #FFF #FFF transparent;
  left: 50%;
  transform: rotate(45deg);
  z-index: 1;
}

.hazard-cluster-bubble:before {
  content: "";
  width: 44px;
  height: 42px;
  z-index: 0;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 8px;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.3);
}

.hazard-cluster-bubble > div:nth-child(2) {
  z-index: 2;
}

.animated { 
  animation-duration: .73s; 
  animation-fill-mode: both;
}

.animated-bubble {
 animation-duration: .7s; 
 animation-fill-mode: both; 
}

.bounce-bubble {
  animation-name: bounce-bubble;
  animation-iteration-count: infinite;
}

@keyframes bounce-bubble {
  0% {
    transform: translate3d(0px, 0px, 0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate3d(0px, -20px, 0);
    animation-timing-function: ease-in ;
  }
  100% {
    transform: translate3d(0px, 0px, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0px, 0px, 0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate3d(0px, -20px, 0);
    animation-timing-function: ease-in ;
  }
  100% {
    transform: translate3d(0px, 0px, 0);
    animation-timing-function: ease-out;
  }
}

.bounce { 
  -webkit-animation-name: bounce; 
  animation-name: bounce; 
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.place-list-marker-infowindow {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center;
}

.place-list-marker-infowindow-extended {
  display:flex; 
  justify-content:center; 
  flex-direction:column; 
  text-align:center;
  &.sos-infowindow {
    .image-window {
      width: 65px;
    }
  }
}

.history-type {
  float: left;
  width: 100%;
  &.tab-type {
    margin: auto;
    float: right;
    display: block;
    max-width: 112px;
    .history-group-type,
    .history-user-type {
      font-size: 10px;
    }
    p {
      padding: 0;
      margin: 0;
      margin-right: 6px;
      margin-top: 1px;
      display: inline-block;
      float: left;
    }
  }
}
.history-group-type,
.history-user-type {
  font-family: 'Roboto', 'Helvetica'; 
  font-size: 13px;
  color: #1a986f;
  border: 2px solid #1a986f;
  font-weight: bold;
  float: left;
  padding: 2px 5px;
  border-radius: 3px;
}
.history-group-type {
  color: #4d03ff;
  border: 2px solid #4d03ff;
}
.place-service-buttons button {
  font-size: 12px;
  line-height: 24px;
}
.place-service-buttons button.edit-button.download-no-text {
  border: 2px solid #0e6aad;
  color: #0e6aad;
  margin-right: 5px;
  &:hover {
    color: #fff;
    background-color: #0e6aad;
  }
}
.place-service-buttons button.edit-button.loading-button {
  background-color: #d89619;
  color: #fff;
  border: 2px solid #d89619;
  &:hover {
    color: #fff;
    background-color: #d89619;
  }
}
.unmounting-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 280px);
  background-color: #000;
  z-index: 999;
  .close-button {
    position: absolute;
    right: 0;
    height: 60px;
    border-radius: 0;
    background-color: #151515;
    color: #fff;
    box-shadow: initial;
    font-weight: 100;
    &:hover {
      color: #fff;
    }
  }
}

.live-table {
  > div {
    background-color: #000;
  }
  thead th{
    background-color: #323232;
  }
  button {
    color: #fff;
  }
}
.openvidu-stream-title {
    background-color: #000;
    width: 100%;
    color: #fff;
    display: flex;
    position: relative;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 0 0 0 20px;
    .close-button.live {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      color: #fff;
      width: 64px;
    }
}
.place-id-info {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 9px;
  color: #ffffff;
  font-family: 'Roboto';
}
//openvidu
#session {
  display: flex;
  width: 100%;
  height: calc(100% - 150px);
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 62px;
}
.video-container-monitor-livego {
  background-color: #1d1d1d;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 4;
  video {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}
#video-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  &.realtime-tracking {
    width: 60%;
    left: 0;
    position: absolute;
  }
}
.stream-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.storage-video-elm {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}
.loading-place-id {
  color: #fff;
  font-family: 'Helvetica';
  font-size: 12px;
  position: absolute;
  padding: 5px;
  color: #828282;
  .delete {
    color: red;
    margin-left: 10px;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
.rotate-and-full-controls.livestream-single-controls {
  button.force-close-session-button {
    background-color: transparent;
    position: absolute;
    right: 0;
    svg {
      width: 17px;
      fill: red;
    }
    p {
      font-size: 12px;
      color: red;
    }
  }
}
.storage-limit-info {
  color: #fff;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 0px;
  span {
    color: #ffcc33!important;
    font-weight: bolder;
  }
  &.no-storage-plan {
    text-align: center;
    background-color: #0a0a0a;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
  }
}
.loader-button-container {
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-button-container > div {
  height: 17px;
}